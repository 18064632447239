<template>
    <div v-if="displayMode=='standard'">
        <collapse-panel title="Options & tris" style="margin-top:0.2rem;" :visible="isOptionAndSortPanelVisible">
            <div>
                <b-checkbox switch size="sm" v-model="excludeOpens" v-if="hasOpens">Résultats fédéraux uniquement</b-checkbox>
                <b-checkbox switch size="sm" v-model="with_ranks">Avec classements intermédiaires</b-checkbox>
                <b-checkbox switch size="sm" v-model="with_subtotals">Avec sous-totaux</b-checkbox>
            </div>
            <div>
                Tri:
                <b-radio-group v-model="sortType" size="sm">
                    <b-form-radio value="execution">Ordre de passage</b-form-radio>
                    <b-form-radio value="rank">Classement</b-form-radio>
                    <b-form-radio value="alphabetical">Alphabétique</b-form-radio>
                </b-radio-group>
            </div>
            <div v-if="sortType=='rank' && value.hasCompulsories">
                <b-form-select v-model="rankSortType" size="sm" >
                    <b-form-select-option value="overall"><b>Total (overall)</b></b-form-select-option>
                    <b-form-select-option-group v-if="value.hasCompulsories" label="Figures">
                        <b-form-select-option v-if="value.hasCompulsories" value="TotalCompulsory"><b>Total</b></b-form-select-option>
                        <b-form-select-option v-if="value.hasCompulsories" value="Compulsory_1">Figure 1</b-form-select-option>
                        <b-form-select-option v-if="value.hasCompulsories && numberOfCompulsories > 1" value="Compulsory_2">Figure 2</b-form-select-option>
                        <b-form-select-option v-if="value.hasCompulsories && numberOfCompulsories > 2" value="Compulsory_3">Figure 3</b-form-select-option>
                        <b-form-select-option v-if="value.hasCompulsories && numberOfCompulsories > 3" value="Compulsory_4">Figure 4</b-form-select-option>
                        <b-form-select-option v-if="value.hasCompulsories && numberOfCompulsories > 4" value="Compulsory_5">Figure 5</b-form-select-option>
                    </b-form-select-option-group>
                    <b-form-select-option-group v-if="value.hasRoutine" label="Routine">
                        <b-form-select-option v-if="value.hasRoutine" value="Routine"><b>Total</b></b-form-select-option>
                        <b-form-select-option v-if="value.hasRoutine" value="RoutineExecution"><i>Exécution</i></b-form-select-option>
                        <b-form-select-option v-if="value.hasRoutine" value="RoutineContent"><i>Contenu</i></b-form-select-option>
                    </b-form-select-option-group>
                    <b-form-select-option-group v-if="value.hasBallet" label="Ballet">
                        <b-form-select-option v-if="value.hasBallet" value="Ballet"><b>Total</b></b-form-select-option>
                        <b-form-select-option v-if="value.hasBallet" value="BalletExecution"><i>Exécution</i></b-form-select-option>
                        <b-form-select-option v-if="value.hasBallet" value="BalletChoreo"><i>Chorégraphie</i></b-form-select-option>
                    </b-form-select-option-group>
                </b-form-select>
            </div>
        </collapse-panel>
        <div ref="render" class="results-render large-table" style="display:flex">
            <slot name="title"></slot>
            <table style="font-size: 0.8rem;">
                <thead>
                    <tr v-if="value.hasCompulsories" class="grid-header">
                        <th rowspan="2" colspan="2">Compétiteur</th>
                        <th :colspan="numberOfCompulsories + colspanOptionsCount">Figures</th>
                        <th v-if="value.hasRoutine" :colspan="2 + colspanOptionsCount">Routine</th>
                        <th v-if="value.hasBallet" :colspan="2 + colspanOptionsCount">Ballet</th>
                        <th colspan="2">Overall</th>
                    </tr>
                    <tr class="grid-header">
                        <th v-if="!value.hasCompulsories" colspan="2">Compétiteur</th>
                        <th v-if="value.hasCompulsories">Fig.1</th>
                        <th v-if="value.hasCompulsories">Fig.2</th>
                        <th v-if="value.hasCompulsories">Fig.3</th>
                        <th v-if="value.hasCompulsories && with_subtotals">Total</th>
                        <th v-if="value.hasCompulsories && with_ranks == true ">Rg.</th>
                        <th v-if="value.hasRoutine">Exec.</th>
                        <th v-if="value.hasRoutine">Cont.</th>
                        <th v-if="value.hasRoutine && with_subtotals">Total</th>
                        <th v-if="value.hasRoutine && with_ranks == true ">Rg.</th>
                        <th v-if="value.hasBallet">Choré.</th>
                        <th v-if="value.hasBallet">Exec.</th>
                        <th v-if="value.hasBallet && with_subtotals">Total</th>
                        <th v-if="value.hasBallet && with_ranks == true ">Rg.</th>
                        <th>Total</th>
                        <th>Rg.</th>
                    </tr>
                </thead>
                <!--<div style="max-height:300px; overflow-y:scroll;">-->
                <tbody>
                    <tr v-for="row in rows" :key="row.id" :class="{'non-participant' : (row.isForfeited == 1 || row.isAbsent == 1) ,'even': (row.index % 2 == 0), 'odd': (row.index %2 !=0), 'open-mode-competitor': (row.isOpen == 1) }">
                        <td class="row-index">{{ row.index }}</td>
                        <td class="name">{{ row.COMPETITOR.name }}</td>
                        <td class="note" v-for="compulsory in row.compulsories" :key="compulsory.compulsory_id">{{ (row.isForfeited || row.isAbsent) ? "" : arrondir(compulsory.noteValue) }}</td>
                        <td class="subtotal" v-if="value.hasCompulsories && with_subtotals">{{ (row.isForfeited || row.isAbsent) ? "" : arrondir(row.totalCompulsories.noteValue) }}</td>
                        <td class="subrank" v-if="value.hasCompulsories && with_ranks==true">{{ (row.isForfeited == 1 || row.isAbsent == 1) ? "" : ( (hasOpens && excludeOpens) ? row.totalCompulsories.federal_rank : row.totalCompulsories.rank) }}</td>
                        <td class="note" v-if="value.hasRoutine">{{ (row.isForfeited || row.isAbsent) ? "" : arrondir(row.routine.execution) }}</td>
                        <td class="note" v-if="value.hasRoutine">{{ (row.isForfeited || row.isAbsent) ? "" : arrondir(row.routine.content) }}</td>
                        <td class="subtotal" v-if="value.hasRoutine && with_subtotals==true">{{ (row.isForfeited || row.isAbsent) ? "" : arrondir(row.routine.note) }}</td>
                        <td class="subrank" v-if="value.hasRoutine && with_ranks==true">{{ (row.isForfeited == 1 || row.isAbsent == 1) ? "" : ( (hasOpens && excludeOpens) ? row.routine.federal_rank : row.routine.rank) }}</td>
                        <td class="note" v-if="value.hasBallet">{{ (row.isForfeited || row.isAbsent) ? "" : arrondir(row.ballet.choreo) }}</td>
                        <td class="note" v-if="value.hasBallet">{{ (row.isForfeited || row.isAbsent) ? "" : arrondir(row.ballet.execution) }}</td>
                        <td class="subtotal" v-if="value.hasBallet && with_subtotals==true">{{ (row.isForfeited || row.isAbsent) ? "" : arrondir(row.ballet.note) }}</td>
                        <td class="subrank" v-if="value.hasBallet && with_ranks==true">{{ (row.isForfeited == 1 || row.isAbsent == 1) ? "" : ( (hasOpens && excludeOpens) ? row.ballet.federal_rank : row.ballet.rank) }}</td>
                        <td class="total">{{ (row.isForfeited == 1 || row.isAbsent == 1) ? "" : arrondir(row.note) }}</td>
                        <td class="rank">{{ (row.isForfeited == 1 || row.isAbsent == 1) ? "" : ( (hasOpens && excludeOpens) ? row.federal_rank : row.rank) }}</td>
                    </tr>
                </tbody>
                <!--</div>-->
            </table>
        </div>
    </div>
    <div v-else style="zoom: 60%;">
        <p></p>
        <table style="font-size:7pt; font-family: 'arial';" class="publish">
            <thead>
                <tr class="grid-header">
                    <th style="width:9cm"></th>
                    <th style="width:1.14cm"><p class="table-row">Fig. 1</p></th>
                    <th style="width:1.14cm"><p class="table-row">Fig. 2</p></th>
                    <th style="width:1.14cm"><p class="table-row">Fig. 3</p></th>
                    <th style="width:1.14cm"><p class="table-row">Figures</p></th>
                    <th style="width:1.14cm"><p class="table-row">Exec.</p></th>
                    <th style="width:1.14cm"><p class="table-row">Cont.</p></th>
                    <th style="width:1.14cm"><p class="table-row">Rout.<br/>Tech.</p></th>
                    <th style="width:1.14cm"><p class="table-row">Tot.<br/>Préc.</p></th>
                    <th style="width:0.9cm"><p class="table-row">Rg.</p></th>
                    <th style="width:1.14cm"><p class="table-row">Choré.</p></th>
                    <th style="width:1.14cm"><p class="table-row">Exec.</p></th>
                    <th style="width:1.14cm"><p class="table-row">Tot.<br/>Ballet</p></th>
                    <th style="width:0.9cm"><p class="table-row">Rg.</p></th>
                    <th style="width:1.14cm"><p class="table-row">Overall</p></th>
                    <th style="width:0.9cm"><p class="table-row">Rg.</p></th>
                </tr>
            </thead>
            <!--<div style="max-height:300px; overflow-y:scroll;">-->
            <tbody>
                <tr v-for="row in rows" :key="row.id" :class="{'even': (row.index % 2 == 0), 'odd': (row.index %2 !=0), 'open-mode-competitor': (row.isOpen == 1) }">
                    <td class="name"><p class="table-row" :style="row.style">{{ row.COMPETITOR.name }}</p></td>
                    <td class="note"><p class="table-row" :style="row.style">{{ value.hasCompulsories && row.compulsories.length > 0 ? arrondir(row.compulsories[0].noteValue) : ''}}</p></td>
                    <td class="note"><p class="table-row" :style="row.style">{{ value.hasCompulsories && row.compulsories.length > 1 ? arrondir(row.compulsories[1].noteValue) : ''}}</p></td>
                    <td class="note"><p class="table-row" :style="row.style">{{ value.hasCompulsories && row.compulsories.length > 2 ? arrondir(row.compulsories[2].noteValue) : ''}}</p></td>
                    <td class="subtotal"><p class="table-row" :style="row.style">{{row.totalCompulsories ? arrondir(row.totalCompulsories.noteValue) : '' }}</p></td>
                    <td class="note"><p class="table-row" :style="row.style">{{ row.routine ? arrondir(row.routine.execution) : '' }}</p></td>
                    <td class="note"><p class="table-row" :style="row.style">{{ row.routine ? arrondir(row.routine.content) : '' }}</p></td>
                    <td class="subtotal"><p class="table-row" :style="row.style">{{ row.routine ? arrondir(row.routine.note) : '' }}</p></td>
                    <td class="subtotal"><p class="table-row" :style="row.style">{{ row.precision ? arrondir(row.precision.note) : '' }}</p></td>
                    <td class="subrank"><p class="table-row" :style="row.style">{{ row.precision ? row.precision.rank : ''}}</p></td>
                    <td class="note"><p class="table-row" :style="row.style">{{ row.ballet ? arrondir(row.ballet.choreo) : '' }}</p></td>
                    <td class="note"><p class="table-row" :style="row.style">{{ row.ballet ? arrondir(row.ballet.execution) : '' }}</p></td>
                    <td class="subtotal"><p class="table-row" :style="row.style">{{ row.ballet ? arrondir(row.ballet.note) : '' }}</p></td>
                    <td class="subrank"><p class="table-row" :style="row.style">{{ row.ballet ? row.ballet.rank : '' }}</p></td>
                    <td class="total"><p class="table-row" :style="row.style">{{ arrondir(row.note) }}</p></td>
                    <td class="rank"><p class="table-row" :style="row.style">{{ row.rank }}</p></td>
                </tr>
            </tbody>
            <!--</div>-->
        </table>
        <p></p>
    </div>
</template>

<script>

    import CollapsePanel from '../Panels/CollapsePanel.vue';
    
    
    export default {
      components: { CollapsePanel },
        data(){
            return {
                columns: [],
                table: [],
                with_ranks: true,
                with_subtotals: true,
                typeDeTri: 'rank',
                sortRankType: 'overall',
                numberOfCompulsories: this.value.level == 'novice' ? 2 : 3,
                allRows: [],
                hasOpens: false,
                excludeOpens: false,
                //rows: []
            };
        },
        props: {
                //category : { type : Object, required: true, },
                //value : { type: Object, default: null, },
                value : { type: Object, required: true, },
                competitors: { type:Array, required: true },
                competitor: { type: Object, default: null},
                includeJudgeDetails: {type: Boolean, default: false},
                isOptionAndSortPanelVisible: { type: Boolean, default: true},
                displayMode: { type: String, default: 'standard'},
            },
        computed:{
            withRanks:{
                get: function()  { return this.with_ranks; },
                set: function(newValue) { this.with_ranks = newValue; this.refresh();}
            },
            sortType :{
                get: function() { return this.typeDeTri; },
                set: function(newValue) { this.typeDeTri = newValue; this.refresh(); }
            },
            rankSortType: {
                get: function() {return this.sortRankType; },
                set: function(newValue) {this.sortRankType = newValue; this.refresh(); }
            },
            colspanOptionsCount(){
                var ret = (this.with_ranks ? 1 : 0)
                        + (this.with_subtotals ? 1 : 0);
                return ret;
            },
            rows(){
                var ret = (!this.hasOpens || !this.excludeOpens)
                        ? this.allRows
                        : this.allRows.filter(comp => !comp.isOpen);
                if(this.displayMode == 'publish')
                    ret.sort((a,b) => a.effective_order - b.effective_order);
                return ret;
            }
        },
        methods:{
            refresh(){
                if(this.value == null) return;
    
                var retRows = this.value.SCORES
                            .map((p,i) => { 
                                var c = p.COMPULSORIES.filter(i => i.judge_id == null && i.compulsory_number != null);

                                var tca = p.COMPULSORIES.filter(i => i.judge_id == null && i.compulsory_number == null);
                                var tc = tca.length > 0 ? tca[0] : null;

                                var ra = p.ROUTINE.filter(i => i.judge_id == null);
                                var r = ra.length > 0 ? ra[0] : null;

                                var ba = p.BALLET.filter(i => i.judge_id == null);
                                var b = ba.length > 0 ? ba[0] : null;

                                p.COMPETITOR = this.competitors.find(i => i.competitor_id == p.competitor_id);
                                p.index = i + 1;
                                p.compulsories = c;
                                p.totalCompulsories = tc;
                                p.routine = r;
                                p.ballet = b;
                                p.competitor_name = p.COMPETITOR.name;
                                p.competitor_shortname = p.COMPETITOR.shortname;
                                p.isOpen = p.COMPETITOR.isOpen;
                                this.hasOpens |= p.isOpen;

                                p.style = p.isOpen ? "color: #C65911; font-style:italic;" : ""
                                
                                return p;
                                }
                            );
  
                var typeDeTri = (this.displayMode == 'publish') ? 'execution' : this.typeDeTri;
                switch(typeDeTri){
                    case "execution":
                        retRows.sort((a,b) => a.effective_order - b.effective_order);
                        break; 
                    case "alphabetical":
                        retRows.sort((a,b) => a.COMPETITOR.name.localeCompare(b.COMPETITOR.name));
                        break;
                    case "rank":
                        switch(this.sortRankType){
                            case 'overall':
                                retRows.sort((a,b) => a.rank - b.rank);
                                break;
                            case 'TotalCompulsory':
                                retRows.sort((a,b) => a.totalCompulsories.rank - b.totalCompulsories.rank);
                                break;
                            case "Compulsory_1":
                                retRows.sort((a,b) => a.compulsories[0].rank - b.compulsories[0].rank);
                                break;
                            case "Compulsory_2":
                                retRows.sort((a,b) => a.compulsories[1].rank - b.compulsories[1].rank);
                                break;
                            case "Compulsory_3":
                                retRows.sort((a,b) => a.compulsories[2].rank - b.compulsories[2].rank);
                                break;
                            case "Compulsory_4":
                                retRows.sort((a,b) => a.compulsories[3].rank - b.compulsories[3].rank);
                                break;
                            case "Compulsory_5":
                                retRows.sort((a,b) => a.compulsories[4].rank - b.compulsories[4].rank);
                                break;
                            case "Routine":
                                retRows.sort((a,b) => a.routine.rank - b.routine.rank);
                                break;
                            case "RoutineExecution":
                                retRows.sort((a,b) => a.routine.execution_rank - b.routine.execution_rank);
                                break;
                            case "RoutineContent":
                                retRows.sort((a,b) => a.routine.content_rank - b.routine.content_rank);
                                 break;
    
                            case "Ballet":
                                retRows.sort((a,b) => a.ballet.rank - b.ballet.rank);
                                break;
                            case "BalletExecution":
                                retRows.sort((a,b) => a.ballet.execution_rank - b.ballet.execution_rank);
                                break;
                            case "BalletChoreo":
                                retRows.sort((a,b) => a.ballet.choreo_rank - b.ballet.choreo_rank);
                                break;
                        }
                }
    
                this.allRows = retRows.map((r,i) => { r.index = i + 1; return r});
            }
        },
        mounted(){
            this.typeDeTri = 'rank';
            this.refresh();
        },

    }
    </script>
    
    <style >
        .results-render{
            display:flex;
            justify-content: center;
        }
        .results-render > table {
            border-collapse: collapse;
            border: solid 2px var(--ffvlOrangePicto);
        }
        .results-render > table  td, .results-render > table th{
            border: solid 1px var(--ffvlOrangePicto);
            text-align: center;
        }
        table.results { margin-top: 10px;}
        .results{ border-collapse: collapse !important; }
        .results > tbody > tr > th { font-size: 0.95rem !important; orientation: landscape; border:darkgrey 1px solid !important;}
        table.results > tbody > tr > td { font-size: 0.75rem !important; orientation: landscape; border:darkgrey 1px solid !important;}
        table.results > thead > tr.grid-header > th { padding-left:3px !important; padding-right:3px !important; background-color:var(--ffvlBleuLogo) !important; }
        .results > tbody > tr > th.total{ background-color: var(--resultTotalHeader);}
        .results > tbody > tr > td.total{ background-color: var(--resultTotal);}
        .results > tbody > tr > th.rank{ background-color: var(--resultRankHeader);}
        .results > tbody > tr > td.rank{ background-color: var(--resultRank);}
        .results > tbody > tr > th.total, .results > tbody > tr > td.total { font-weight: bolder; color: var(--primary);}
        .results > tbody > tr > th.sorted{ background-color: var(--sortedHeader);}
        .results > tbody > tr > td.sorted{ background-color: var(--sortedCell);}
    
          tr.even td { background-color: beige;}
        tr.odd td{ background-color: #cdcdfd;}
    
        .trophy {
            background-color: transparent !important;
            border-left: none !important;
            border-right: solid 2px var(--ffvlOrangePicto) !important;
            padding-left: 5px;
            padding-right: 5px;
        }
    
        .row-index{ padding-left: 7px; padding-right: 7px;}
    
        .name{ min-width:100px; max-width:300px; text-overflow: ellipsis; padding-left: 2px; text-align: left !important;}
    
        .note { width: 45px; }
        .subtotal { width: 45px; }
        .subrank { width: 25px; }
    
        @media screen and (max-width: 767px) and (orientation: portrait) {
            .name{ max-width: 150px; }
            /*.results-render.large-table table {
    
            }*/
            .results-render.large-table{
                justify-content: flex-start;
                transform-origin: top left;
                width:100%;
                height:100%;
                
                transform: translateX(100%) translateY(0) rotate(90deg) ;
                position:relative;
                overflow:visible;
            }
        /*/    .results-render.large-table {
                position: absolute;
                overflow:auto;
                background-color:green;
                width: 85%;
                height: 800px;
                justify-content:flex-start;
            }
            .results-render.large-table table {
                width:100%;
                background-color: yellow;
                justify-items:center;
                transform: rotate(90deg)  ;
                overflow: visible;
                margin-bottom:40px;
                width: 700px;
             }*/
    
    
        }
    </style>
    